import { ArrowLeft } from '@benepass/icons';
import MinScreenProvider from 'utils/src/hooks/use-min-screen';
import Button from 'react-components/src/components/button';
import Text from 'react-components/src/components/text';
import Loader from './loader';
import Title from './title';
import texts from '../constants/texts';
import Icon from './icon';

import { Container, TitleContainer, ActionsContainer } from './layout';

export type State = 'success' | 'error' | 'in_progress' | 'initialized' | 'canceled';

export type StateWithMessage = {
  state: State;
  message?: string;
  code?: string;
};

const screens = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  xll: '1536px',
};

type Props = {
  state: StateWithMessage;
  onGoBack: () => void;
};

const MainContainer = ({ state, onGoBack }: Props) => {
  return (
    <MinScreenProvider screens={screens}>
      <div className="flex">
        <div className="max-w-lg mx-auto">
          {state.state === 'in_progress' ? (
            <div className="flex items-center justify-center h-screen">
              <Loader />
            </div>
          ) : null}

          {state.state === 'error' ? (
            <Container>
              <TitleContainer>
                <Icon type={['unknown', 'auth'].includes(state.code || '') ? 'error' : 'bank-error'} />

                <div className="mt-8 text-center">
                  <Title>{texts.error.title}</Title>

                  <Text size="lg" className="text-grayscale-64">
                    {['unknown', 'auth'].includes(state.code || '') ? texts.error.unknown : texts.error.link}
                  </Text>
                </div>
              </TitleContainer>

              <ActionsContainer>
                {!state.code ? (
                  <Button className="w-full max-w-md" variant="ghost">
                    <Text className="ml-3 text-indigo-100">Contact us</Text>
                  </Button>
                ) : null}

                <Button className="w-full max-w-md" onClick={onGoBack}>
                  <ArrowLeft size={20} />
                  <Text className="ml-3 text-grayscale-0">Go back</Text>
                </Button>
              </ActionsContainer>
            </Container>
          ) : null}

          {state.state === 'success' ? (
            <Container>
              <TitleContainer>
                <Icon type="bank-success" />

                <div className="mt-8 text-center">
                  <Title>{texts.success.title}</Title>

                  <Text size="lg" className="text-grayscale-64">
                    {state.code === 'verification-pending' ? texts.success.pendingVerification : texts.success.general}
                  </Text>
                </div>
              </TitleContainer>

              <ActionsContainer>
                <Button className="w-full max-w-md" onClick={onGoBack}>
                  <Text className="ml-3 text-grayscale-0">Go to settings</Text>
                </Button>
              </ActionsContainer>
            </Container>
          ) : null}
        </div>
      </div>
    </MinScreenProvider>
  );
};

export default MainContainer;
