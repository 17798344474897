import React from 'react';

const Loader = () => (
  <div className="inline-block self-center">
    <svg className="animate-spin" height="40" width="40" viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="10" fill="white" stroke="gray" strokeWidth="2" />
      <path d="m 20 20 l -5 -20 l 10 0 z" stroke="white" fill="white" strokeWidth="1" />
    </svg>
  </div>
);

export default Loader;
