import React from 'react';

const BankErrorIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.68181 12.5867C7.48301 12.7469 7.33763 12.967 7.26593 13.2165C7.19424 13.4659 7.19981 13.7323 7.28187 13.9783C7.36393 14.2243 7.51839 14.4378 7.72371 14.589C7.92903 14.7402 8.17498 14.8216 8.42727 14.8217H31.7C31.9474 14.822 32.1889 14.7441 32.3922 14.5987C32.5954 14.4532 32.7506 14.2471 32.837 14.008C32.9234 13.754 32.9298 13.4785 32.8553 13.2206C32.7809 12.9626 32.6294 12.7355 32.4224 12.5717L21.4151 5.30642C21.2151 5.12458 20.9617 5.01735 20.6955 5.00193C20.4293 4.98651 20.1658 5.06381 19.9473 5.22142L7.75697 12.5392C7.73031 12.5521 7.70511 12.5681 7.68181 12.5867Z"
      fill="#DF3333"
    />
    <path
      d="M17.8262 19.0083C17.5989 19.2428 17.2906 19.3745 16.9691 19.3745H16.06C15.9796 19.3745 15.9025 19.4074 15.8457 19.466C15.7889 19.5246 15.757 19.6041 15.757 19.687V25.9372C15.757 26.0201 15.7889 26.0996 15.8457 26.1582C15.9025 26.2168 15.9796 26.2497 16.06 26.2497H16.9691C17.2906 26.2497 17.5989 26.3814 17.8262 26.6158C18.0535 26.8503 18.1812 27.1682 18.1812 27.4997C18.1812 27.8313 18.0535 28.1492 17.8262 28.3837C17.5989 28.6181 17.2906 28.7498 16.9691 28.7498H9.69635C9.37488 28.7498 9.06657 28.6181 8.83925 28.3837C8.61194 28.1492 8.48423 27.8313 8.48423 27.4997C8.48423 27.1682 8.61194 26.8503 8.83925 26.6158C9.06657 26.3814 9.37488 26.2497 9.69635 26.2497H10.6054C10.6858 26.2497 10.7629 26.2168 10.8197 26.1582C10.8765 26.0996 10.9085 26.0201 10.9085 25.9372V19.687C10.9085 19.6041 10.8765 19.5246 10.8197 19.466C10.7629 19.4074 10.6858 19.3745 10.6054 19.3745H9.69635C9.37488 19.3745 9.06657 19.2428 8.83925 19.0083C8.61194 18.7739 8.48423 18.456 8.48423 18.1244C8.48423 17.7929 8.61194 17.4749 8.83925 17.2405C9.06657 17.0061 9.37488 16.8744 9.69635 16.8744H16.9691C17.2906 16.8744 17.5989 17.0061 17.8262 17.2405C18.0535 17.4749 18.1812 17.7929 18.1812 18.1244C18.1812 18.456 18.0535 18.7739 17.8262 19.0083Z"
      fill="#DF3333"
    />
    <path
      d="M31.1595 19.0083C30.9322 19.2428 30.6239 19.3745 30.3024 19.3745H29.3933C29.313 19.3745 29.2359 19.4074 29.179 19.466C29.1222 19.5246 29.0903 19.6041 29.0903 19.687V25.9372C29.0903 26.0201 29.1222 26.0996 29.179 26.1582C29.2359 26.2168 29.313 26.2497 29.3933 26.2497H30.3024C30.6239 26.2497 30.9322 26.3814 31.1595 26.6158C31.3868 26.8503 31.5145 27.1682 31.5145 27.4997C31.5145 27.8313 31.3868 28.1492 31.1595 28.3837C30.9322 28.6181 30.6239 28.7498 30.3024 28.7498H23.0297C22.7082 28.7498 22.3999 28.6181 22.1726 28.3837C21.9453 28.1492 21.8176 27.8313 21.8176 27.4997C21.8176 27.1682 21.9453 26.8503 22.1726 26.6158C22.3999 26.3814 22.7082 26.2497 23.0297 26.2497H23.9388C24.0191 26.2497 24.0962 26.2168 24.1531 26.1582C24.2099 26.0996 24.2418 26.0201 24.2418 25.9372V19.687C24.2418 19.6041 24.2099 19.5246 24.1531 19.466C24.0962 19.4074 24.0191 19.3745 23.9388 19.3745H23.0297C22.7082 19.3745 22.3999 19.2428 22.1726 19.0083C21.9453 18.7739 21.8176 18.456 21.8176 18.1244C21.8176 17.7929 21.9453 17.4749 22.1726 17.2405C22.3999 17.0061 22.7082 16.8744 23.0297 16.8744H30.3024C30.6239 16.8744 30.9322 17.0061 31.1595 17.2405C31.3868 17.4749 31.5145 17.7929 31.5145 18.1244C31.5145 18.456 31.3868 18.7739 31.1595 19.0083Z"
      fill="#DF3333"
    />
    <path
      d="M7.27208 30.6248H32.7266C33.0613 30.6248 33.3327 30.9047 33.3327 31.2499V34.375C33.3327 34.7202 33.0613 35 32.7266 35H7.27208C6.93736 35 6.66602 34.7202 6.66602 34.375V31.2499C6.66602 30.9047 6.93736 30.6248 7.27208 30.6248Z"
      fill="#DF3333"
    />
    <circle cx="34" cy="34" r="12" fill="#F9D6D6" stroke="#FCEBEB" strokeWidth="4" />
    <path
      d="M29.2494 37.9024L33.1887 33.9631L29.2494 30.0239C29.0152 29.7896 29.0152 29.4099 29.2494 29.1757C29.4836 28.9414 29.8633 28.9414 30.0976 29.1757L34.0369 33.115L37.9761 29.1757C38.2104 28.9414 38.5901 28.9414 38.8243 29.1757C39.0586 29.4099 39.0586 29.7896 38.8243 30.0239L34.8851 33.9631L38.8243 37.9024C39.0586 38.1367 39.0586 38.5164 38.8243 38.7506C38.5901 38.9848 38.2104 38.9848 37.9761 38.7506L34.0369 34.8113L30.0976 38.7506C29.8633 38.9848 29.4836 38.9848 29.2494 38.7506C29.0152 38.5164 29.0152 38.1367 29.2494 37.9024Z"
      fill="#DF3333"
    />
  </svg>
);

export default BankErrorIcon;
