import BankSuccessIcon from './bank-success';
import BankErrorIcon from './bank-error';
import ErrorIcon from './error';
import React from 'react';

type Props = {
  /**
   * Icon Type
   */
  type: 'bank-success' | 'success' | 'bank-error' | 'error';
};

const Icon = ({ type }: Props) => {
  const SelectedIcon = React.useMemo(() => {
    switch (type) {
      case 'bank-error':
        return BankErrorIcon;

      case 'bank-success':
        return BankSuccessIcon;

      case 'error':
        return ErrorIcon;

      case 'success':
      default:
        return null;
    }
  }, [type]);

  const backgroundClassName = React.useMemo(() => {
    switch (type) {
      case 'bank-error':
      case 'error':
        return 'bg-red-10';

      case 'bank-success':
      case 'success':
        return 'bg-mint-10';

      default:
        return 'bg-grayscale-10';
    }
  }, [type]);

  if (!SelectedIcon) return null;

  return (
    <div className={['h-20 w-20 rounded-lg flex items-center justify-center', backgroundClassName].join(' ')}>
      <SelectedIcon />
    </div>
  );
};

export default Icon;
