const texts = {
  error: {
    title: 'Something went wrong',
    unknown: 'The application has encountered an error, please close the window and try again later.',
    link: 'The application has encountered an error, please close the window and try again later. If you still have problems linking your account you can submit a ticket.',
  },
  success: {
    title: 'Bank account linked!',
    pendingVerification:
      'Your bank account was successfully linked, but we still need to verify it! Go to settings and click "Verify account" to enter your micro-deposit amounts and finish linking your bank account to your Benepass account.',
    general: 'Your bank account was successfully linked! You can see its status and information in your settings page.',
  },
};

export default texts;
