import React from 'react';

const ErrorIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 35C23.9782 35 27.7936 33.4196 30.6066 30.6066C33.4196 27.7936 35 23.9782 35 20C35 16.0218 33.4196 12.2064 30.6066 9.3934C27.7936 6.58035 23.9782 5 20 5C16.0218 5 12.2064 6.58035 9.3934 9.3934C6.58035 12.2064 5 16.0218 5 20C5 23.9782 6.58035 27.7936 9.3934 30.6066C12.2064 33.4196 16.0218 35 20 35ZM17.5756 14.9244C17.222 14.5828 16.7484 14.3938 16.2567 14.3981C15.7651 14.4024 15.2949 14.5996 14.9472 14.9472C14.5996 15.2949 14.4024 15.7651 14.3981 16.2567C14.3938 16.7484 14.5828 17.222 14.9244 17.5756L17.3488 20L14.9244 22.4244C14.7453 22.5973 14.6025 22.8042 14.5042 23.033C14.4059 23.2617 14.3542 23.5078 14.352 23.7567C14.3499 24.0057 14.3973 24.2526 14.4916 24.483C14.5859 24.7135 14.7251 24.9228 14.9011 25.0989C15.0772 25.2749 15.2865 25.4141 15.517 25.5084C15.7474 25.6027 15.9943 25.6501 16.2433 25.648C16.4922 25.6458 16.7382 25.5941 16.967 25.4958C17.1958 25.3975 17.4027 25.2547 17.5756 25.0756L20 22.6512L22.4244 25.0756C22.778 25.4172 23.2516 25.6062 23.7432 25.6019C24.2349 25.5976 24.7051 25.4004 25.0528 25.0528C25.4004 24.7051 25.5976 24.2349 25.6019 23.7433C25.6062 23.2516 25.4172 22.778 25.0756 22.4244L22.6512 20L25.0756 17.5756C25.4172 17.222 25.6062 16.7484 25.6019 16.2567C25.5976 15.7651 25.4004 15.2949 25.0528 14.9472C24.7051 14.5996 24.2349 14.4024 23.7432 14.3981C23.2516 14.3938 22.778 14.5828 22.4244 14.9244L20 17.3488L17.5756 14.9244Z"
      fill="#DF3333"
    />
  </svg>
);

export default ErrorIcon;
