import React from 'react';

const BankSuccessIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.68279 12.5867C7.48399 12.7469 7.3386 12.967 7.26691 13.2165C7.19522 13.4659 7.20079 13.7323 7.28285 13.9783C7.36491 14.2243 7.51937 14.4378 7.72469 14.589C7.93 14.7402 8.17595 14.8216 8.42824 14.8217H31.701C31.9484 14.822 32.1899 14.7441 32.3931 14.5987C32.5964 14.4532 32.7516 14.2471 32.8379 14.008C32.9243 13.754 32.9308 13.4785 32.8563 13.2206C32.7819 12.9626 32.6304 12.7355 32.4234 12.5717L21.4161 5.30642C21.2161 5.12458 20.9627 5.01735 20.6964 5.00193C20.4302 4.98651 20.1667 5.06381 19.9482 5.22142L7.75794 12.5392C7.73128 12.5521 7.70608 12.5681 7.68279 12.5867Z"
      fill="#0ECCA0"
    />
    <path
      d="M17.8272 19.0083C17.5998 19.2428 17.2915 19.3745 16.9701 19.3745H16.061C15.9806 19.3745 15.9035 19.4074 15.8467 19.466C15.7899 19.5246 15.7579 19.6041 15.7579 19.687V25.9372C15.7579 26.0201 15.7899 26.0996 15.8467 26.1582C15.9035 26.2168 15.9806 26.2497 16.061 26.2497H16.9701C17.2915 26.2497 17.5998 26.3814 17.8272 26.6158C18.0545 26.8503 18.1822 27.1682 18.1822 27.4997C18.1822 27.8313 18.0545 28.1492 17.8272 28.3837C17.5998 28.6181 17.2915 28.7498 16.9701 28.7498H9.69733C9.37585 28.7498 9.06755 28.6181 8.84023 28.3837C8.61291 28.1492 8.48521 27.8313 8.48521 27.4997C8.48521 27.1682 8.61291 26.8503 8.84023 26.6158C9.06755 26.3814 9.37585 26.2497 9.69733 26.2497H10.6064C10.6868 26.2497 10.7639 26.2168 10.8207 26.1582C10.8775 26.0996 10.9094 26.0201 10.9094 25.9372V19.687C10.9094 19.6041 10.8775 19.5246 10.8207 19.466C10.7639 19.4074 10.6868 19.3745 10.6064 19.3745H9.69733C9.37585 19.3745 9.06755 19.2428 8.84023 19.0083C8.61291 18.7739 8.48521 18.456 8.48521 18.1244C8.48521 17.7929 8.61291 17.4749 8.84023 17.2405C9.06755 17.0061 9.37585 16.8744 9.69733 16.8744H16.9701C17.2915 16.8744 17.5998 17.0061 17.8272 17.2405C18.0545 17.4749 18.1822 17.7929 18.1822 18.1244C18.1822 18.456 18.0545 18.7739 17.8272 19.0083Z"
      fill="#0ECCA0"
    />
    <path
      d="M31.1605 19.0083C30.9332 19.2428 30.6249 19.3745 30.3034 19.3745H29.3943C29.3139 19.3745 29.2369 19.4074 29.18 19.466C29.1232 19.5246 29.0913 19.6041 29.0913 19.687V25.9372C29.0913 26.0201 29.1232 26.0996 29.18 26.1582C29.2369 26.2168 29.3139 26.2497 29.3943 26.2497H30.3034C30.6249 26.2497 30.9332 26.3814 31.1605 26.6158C31.3878 26.8503 31.5155 27.1682 31.5155 27.4997C31.5155 27.8313 31.3878 28.1492 31.1605 28.3837C30.9332 28.6181 30.6249 28.7498 30.3034 28.7498H23.0307C22.7092 28.7498 22.4009 28.6181 22.1736 28.3837C21.9462 28.1492 21.8185 27.8313 21.8185 27.4997C21.8185 27.1682 21.9462 26.8503 22.1736 26.6158C22.4009 26.3814 22.7092 26.2497 23.0307 26.2497H23.9398C24.0201 26.2497 24.0972 26.2168 24.154 26.1582C24.2109 26.0996 24.2428 26.0201 24.2428 25.9372V19.687C24.2428 19.6041 24.2109 19.5246 24.154 19.466C24.0972 19.4074 24.0201 19.3745 23.9398 19.3745H23.0307C22.7092 19.3745 22.4009 19.2428 22.1736 19.0083C21.9462 18.7739 21.8185 18.456 21.8185 18.1244C21.8185 17.7929 21.9462 17.4749 22.1736 17.2405C22.4009 17.0061 22.7092 16.8744 23.0307 16.8744H30.3034C30.6249 16.8744 30.9332 17.0061 31.1605 17.2405C31.3878 17.4749 31.5155 17.7929 31.5155 18.1244C31.5155 18.456 31.3878 18.7739 31.1605 19.0083Z"
      fill="#0ECCA0"
    />
    <path
      d="M7.27305 30.6248H32.7276C33.0623 30.6248 33.3337 30.9047 33.3337 31.2499V34.375C33.3337 34.7202 33.0623 35 32.7276 35H7.27305C6.93833 35 6.66699 34.7202 6.66699 34.375V31.2499C6.66699 30.9047 6.93833 30.6248 7.27305 30.6248Z"
      fill="#0ECCA0"
    />
    <circle cx="34" cy="34" r="12" fill="#CFF5EC" stroke="#E7FAF5" strokeWidth="4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.7908 30.8694C39.0697 31.1406 39.0697 31.5803 38.7908 31.8515L33.0765 37.4071C32.7976 37.6783 32.3453 37.6783 32.0664 37.4071L29.2092 34.6293C28.9303 34.3581 28.9303 33.9184 29.2092 33.6472C29.4882 33.376 29.9404 33.376 30.2194 33.6472L32.5714 35.9339L37.7807 30.8694C38.0596 30.5982 38.5119 30.5982 38.7908 30.8694Z"
      fill="#0ECCA0"
    />
  </svg>
);

export default BankSuccessIcon;
