/**
 * Send a message to the "opener" (other browser tab) or in case it was opened from
 * the mobile app it'll send an event to React Native's webview.
 *
 * @param payload - data to send
 */
export const sendEvent = (payload: Record<string, number | string>): void => {
  const body = JSON.stringify(payload);

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(body);
  } else if (window.opener) {
    window.opener.postMessage(body, '*');
  }
};
