import { baseUrl } from '../constants/api';

/**
 * Check auth with BenefitsAPI.
 *
 * @description
 *  it receives a token (that is stored on localStorage or comes as query param on the URL).
 *  it sends a request to BenefitsAPI and then extracts the user data.
 *
 * @returns { boolean }: True if auth is correct
 */
export const checkAuthAndIdentifyUser = async (token: string): Promise<boolean> => {
  const url = `${baseUrl}/v1/users/`;

  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.ok;
};
