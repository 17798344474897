import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

const ActionButtonsContainer = ({ children, ...props }: Props) => (
  <div className="flex flex-col gap-6 items-center justify-center w-full mt-auto md:mt-24 mb-8 md:mb-0" {...props}>
    {children}
  </div>
);

export default ActionButtonsContainer;
