import React from 'react';

import MainContainer, { State, StateWithMessage } from './components/main-container';
import { checkAuthAndIdentifyUser } from './utils/auth';
import { sendEvent } from './utils/webview';
import { baseUrl } from './constants/api';

import type {
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
} from 'react-plaid-link';

type EnhancedPlaidLinkOnSuccessMetadata = PlaidLinkOnSuccessMetadata & {
  account_id?: string;
};

const baseRequest = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
};

export const AppAdmin = () => {
  const [state, setState] = React.useState<StateWithMessage>({ state: 'in_progress' });
  const [internalToken, setInternalToken] = React.useState<string>('');
  // eslint-disable-next-line
  const urlParams = new URLSearchParams(location.search);
  const employerId = urlParams.get('employerId');

  const endpoints = React.useMemo(() => {
    return {
      link: 'v2/admin/platform-funding/bank-accounts/',
      verify: 'v2/admin/platform-funding/bank-accounts/:id/verify/',
      plaidLinkToken: 'v2/admin/platform-funding/bank-accounts/:id/token/',
    };
  }, []);

  /**
   * Make a POST Request to Benefits API. This receives the endpoint and the body
   * (object that will stringified) and returns the API's response.
   *
   * @param endpoint example: 'v1/banks_account/link'
   * @param body data to send to that endpoint
   */
  const postAPI = async (endpoint: string, body: Record<string, unknown>): Promise<unknown> => {
    try {
      const url = `${baseUrl}/${endpoint}`;
      const payload = JSON.stringify(body);
      const benefitsApiToken = internalToken || localStorage.getItem('token');

      const response = await fetch(url, {
        ...baseRequest,
        headers: {
          ...baseRequest.headers,
          ...(employerId ? { 'x-benepass-employerId': employerId } : {}),
          Authorization: benefitsApiToken ? `Bearer ${benefitsApiToken}` : '',
        },
        body: payload,
      });

      if (!response.ok) throw new Error('Error on the request');
      const data = await response.json();

      return data;
      // eslint-disable-next-line
    } catch (error: any) {
      updateState('error', error.message || 'Something happen');
      return null;
    }
  };

  // eslint-disable-next-line
  const updateState = (postState: State, message: string = '', code: string = ''): void => {
    sendEvent({ text: message, type: postState, code });
    setState({ state: postState, message, code });
  };

  const onExit = React.useCallback(
    (error: null | PlaidLinkError, _: PlaidLinkOnExitMetadata) => {
      // eslint-disable-next-line
      if (!!error) {
        updateState('error', 'Error on Plaid Side', error.error_code);
      } else {
        updateState('error', 'cancelled', 'unknown');
      }
    },
    [updateState]
  );

  const checkAuth = React.useCallback(async (token: string): Promise<boolean> => {
    try {
      const res = await checkAuthAndIdentifyUser(token);
      return res;
    } catch (error) {
      updateState('error', (error as { message: string })?.message || 'Something happen', 'auth');
      return false;
    }
  }, []);

  const getPlaidLinkToken = React.useCallback(
    async (bank_account: string | undefined, token: string) => {
      try {
        const hasBankAccount = !!bank_account;
        const generate_plaid_link_token = hasBankAccount ? { bank_account } : {};
        let url = `${baseUrl}/${endpoints.plaidLinkToken}`;

        if (hasBankAccount) {
          url = url.replace(':id/', `${bank_account}/`);
        } else {
          url = url.replace(':id/', '');
        }

        const response = await fetch(url, {
          ...baseRequest,
          headers: {
            ...(employerId ? { 'x-benepass-employerId': employerId } : {}),
            ...baseRequest.headers,
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ generate_plaid_link_token }),
        });

        if (!response.ok) {
          throw new Error("Error getting Plaid 's token");
        }

        const data = await response.json();
        return data?.token ?? data;
      } catch {
        return false;
      }
    },
    [endpoints, employerId]
  );

  const verifyBankAccount = (bank_account: string) => {
    return async (plaid_public_token: string, _metadata: PlaidLinkOnSuccessMetadata) => {
      updateState('in_progress');

      let endpoint = endpoints.verify;

      // eslint-disable-next-line
      if (!!bank_account) {
        endpoint = endpoint.replace(':id/', `${bank_account}/`);
      } else {
        endpoint = endpoint.replace(':id/', '');
      }

      await postAPI(endpoint, {
        plaid_public_token,
      });

      updateState('success', 'Successfully verified bank account');
    };
  };

  /**
   * Link entered bank account on BenefitsAPI.
   *
   * @description
   *  send a POST request to BenefitsAPI to link bank account. It'll also handle the response here
   *  and update the state (checking if it has microdeposits verification pending).
   */
  // eslint-disable-next-line
  const linkBankAccount = async (plaid_public_token: string, metadata: EnhancedPlaidLinkOnSuccessMetadata) => {
    const { account_id, accounts } = metadata;

    updateState('in_progress');

    const value = await postAPI(endpoints.link, {
      plaid_public_token,
      plaid_selected_account_id: account_id,
    });

    if (!value) {
      return updateState('error', 'Error linking bank account');
    }

    const [firstAccount] = accounts;
    const verificationPending = firstAccount?.verification_status === 'pending_manual_verification';

    const message = verificationPending ? 'Pending verification' : 'Successfully linked bank account!';
    updateState('success', message, verificationPending ? 'verification-pending' : '');
  };

  const storeInternalTokenOnStorage = (token: string): void => {
    localStorage.setItem('token', token);
    // eslint-disable-next-line
    location.replace('#');
    setInternalToken(token);
  };

  React.useEffect(() => {
    const storageToken = localStorage.getItem('token');

    if (storageToken) {
      setInternalToken(storageToken);
    }
    // eslint-disable-next-line
  }, [localStorage.getItem('token')]);

  // eslint-disable-next-line
  const initializePlaid = React.useCallback(async (auth: string): Promise<void> => {
    // eslint-disable-next-line
    const [, , ...data] = location.hash.substr(1).split('/');
    const validAuth = await checkAuth(auth);

    storeInternalTokenOnStorage(auth);

    if (!validAuth) {
      localStorage.removeItem('token');
      return updateState('error', 'No token', 'auth');
    }

    const [bank_account] = data;
    const token = await getPlaidLinkToken(bank_account, auth);

    if (!token) {
      updateState('error', 'Error getting Plaid token', 'auth');
      // eslint-disable-next-line
      return;
    }

    const config: PlaidLinkOptions = {
      token,
      // eslint-disable-next-line
      onEvent: console.log,
      onSuccess: bank_account ? verifyBankAccount(bank_account) : linkBankAccount,
      onExit,
    };

    if (window.Plaid) {
      window.Plaid.create(config).open();
      updateState('initialized');
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    // eslint-disable-next-line
    const [, auth] = location.hash.substr(1).split('/');

    if (auth) {
      initializePlaid(auth);
    } else {
      /**
       * In case there's no token on the url it'll try getting the token on the local
       * storage and if there's one there it'll initialize Plaid using that, if not
       * it'll update the state to auth error.
       */
      const storedToken = localStorage.getItem('token');
      if (storedToken) initializePlaid(storedToken);
      else updateState('error', 'No token stored', 'auth');
    }
    // eslint-disable-next-line
  }, []);

  const handleGoBackClick = () => {
    sendEvent({ text: '', type: 'success', code: 'close-window' });
    setTimeout(() => {
      window.close();
    }, 500);
  };

  return <MainContainer state={state} onGoBack={handleGoBackClick} />;
};
