import Text from 'react-components/src/components/text';

import { useMinScreen } from 'utils/src/hooks/use-min-screen';

type Props = { children: string };

const Title = ({ children }: Props) => {
  const { min } = useMinScreen();
  const isDesktop = min`md`;

  return <Text type={isDesktop ? 'display-3' : 'header-1'}>{children}</Text>;
};

export default Title;
