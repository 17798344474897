import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

const TitleContainer = ({ children, ...props }: Props) => (
  <div className="flex flex-col items-center justify-center flex-1 md:flex-none" {...props}>
    {children}
  </div>
);

export default TitleContainer;
