import * as Sentry from '@sentry/browser';

import { createRoot } from 'react-dom/client';

import App from './App';

import './css/tailwind.css';

import { BrowserTracing } from '@sentry/tracing';

document.addEventListener('DOMContentLoaded', () => {
  Sentry.init({
    dsn: 'https://a844c39a2fed49fb8ec9b7d9692bce80@o463927.ingest.sentry.io/6350988',
    release: 'plaid',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
});

const container = document.getElementById('app')!;
const root = createRoot(container);
root.render(<App />);
