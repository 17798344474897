import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

const Container = ({ children, ...props }: Props) => (
  <div className="flex flex-col items-center justify-center h-screen px-4 xl:px-0" {...props}>
    {children}
  </div>
);

export default Container;
