import { AppAdmin } from './AppAdmin';
import { EmployeeApp } from './AppEmployee';

const App = () => {
  if (window.location.pathname === '/admin.html') {
    return <AppAdmin />;
  }

  return <EmployeeApp />;
};

export default App;
